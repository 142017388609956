<script setup>
import { computed } from "vue";
import { useRootStore } from "~/javascript/stores/root";

const rootStore = useRootStore();

const indicatorClasses = computed(() => {
  return {
    loading: rootStore.loading,
    hidden: !(rootStore.loading),
  };
});
</script>

<template>
  <div class="load-indicator" :class="indicatorClasses">
    <div class="bar" />
  </div>
</template>

<style>
@import "~/stylesheets/load_indicator.scss";
</style>


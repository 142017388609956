import { defineStore } from "pinia";

export const useRootStore = defineStore({
  id: "root",
  
  state: () => ({
    loading: true,
  }),

  actions: {
    toggleLoading(loading = true) {
      this.loading = loading;
    },
  },
});

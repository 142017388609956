import { useAdminWorkspaceStore } from "~/javascript/stores/admin/workspaces";

const WorkspacesEdit = () =>
  import("~/javascript/views/admin/workspaces/WorkspacesEdit.vue");
const WorkspacesIndex = () =>
  import("~/javascript/views/admin/workspaces/WorkspacesIndex.vue");
const WorkspacesShow = () =>
  import("~/javascript/views/admin/workspaces/WorkspacesShow.vue");

const setCurrentWorkspace = (to, from, next) => {
  const store = useAdminWorkspaceStore();
  store.loadWorkspace(to.params.workspace_id);
  next();
};

export const workspaceRoutes = [
  {
    path: "workspaces",
    name: "Workspaces",
    children: [
      {
        path: "",
        component: WorkspacesIndex,
      },
      {
        path: ":workspace_id",
        name: "Workspace Show",
        beforeEnter: setCurrentWorkspace,
        children: [
          {
            path: "",
            name: "Workspace Home",
            component: WorkspacesShow,
          },
          {
            path: "edit",
            name: "Edit Workspace",
            component: WorkspacesEdit,
          },
        ],
      },
    ],
  },
];

import { defineStore } from "pinia";
import { useContactStore } from "~/javascript/stores/contacts";
import { useLeadStore } from "~/javascript/stores/leads";
import { useMediaScheduleStore } from "~/javascript/stores/media_schedules";
import { useOrgLocationStore } from "~/javascript/stores/org_locations";
import { useOrgStore } from "~/javascript/stores/organizations";
import { useTargetZipStore } from "~/javascript/stores/target_zips";
import { useTargetZoneStore } from "~/javascript/stores/target_zones";
import { useTrackingTagStore } from "~/javascript/stores/tracking_tags";
import { useUserStore } from "~/javascript/stores/users";
import { useWebsiteStore } from "~/javascript/stores/websites";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";

export const usePathStore = defineStore({
  id: "paths",

  getters: {
    // base route
    workspaceRoute() {
      const store = useWorkspaceStore();

      return `/${store.currentWorkspace?.identifier}`;
    },

    // current getters
    currentOrg() {
      return useOrgStore().currentOrg;
    },

    currentOrgLocation() {
      return useOrgLocationStore().currentOrgLocation;
    },

    currentContact() {
      return useContactStore().currentContact;
    },

    currentLead() {
      return useLeadStore().currentLead;
    },

    currentMediaSchedule() {
      return useMediaScheduleStore().currentMediaSchedule;
    },

    currentTargetZone() {
      return useTargetZoneStore().currentTargetZone;
    },

    currentWebsite() {
      return useWebsiteStore().currentWebsite;
    },

    currentTargetZip() {
      return useTargetZipStore().currentTargetZip;
    },

    currentTrackingTag() {
      return useTrackingTagStore().currentTrackingTag;
    },

    // paths
    dashboardPath() {
      return `${this.workspaceRoute}/dashboard`;
    },

    deptsPath() {
      return `${this.workspaceRoute}/departments`;
    },

    orgsPath() {
      return `${this.workspaceRoute}/orgs`;
    },

    orgPath() {
      return (identifier) => {
        const idParam = identifier || this.currentOrg?.identifier;
        return `${this.orgsPath}/${idParam}`;
      };
    },

    orgEditPath() {
      return (id) => {
        const idParam = id || this.currentOrg?.id;
        return `${this.orgsPath}/${idParam}/edit`;
      };
    },

    orgsCreatePath() {
      return `${this.orgsPath}/create`;
    },

    orgsUploadPath() {
      return `${this.orgsPath}/upload`;
    },

    targetZonesPath() {
      return `${this.workspaceRoute}/target_zones`;
    },

    targetZonePath() {
      return (id) => {
        const idParam = id || this.currentTargetZone?.id;
        return `${this.targetZonesPath}/${idParam}`;
      };
    },

    targetZoneCreatePath() {
      return `${this.targetZonesPath}/create`;
    },

    targetZoneEditPath() {
      return (id) => {
        const idParam = id || this.currentTargetZone?.id;
        return `${this.targetZonesPath}/${idParam}/edit`;
      };
    },

    targetZipsPath() {
      return `${this.workspaceRoute}/target_zips`;
    },

    targetZipPath() {
      return (id) => {
        const idParam = id || this.currentTargetZip?.id;
        return `${this.targetZipsPath}/${idParam}`;
      };
    },

    targetZipsCreatePath() {
      return `${this.targetZipsPath}/create`;
    },

    targetZipEditPath() {
      return (id) => {
        const idParam = id || this.currentTargetZip?.id;
        return `${this.targetZipsPath}/${idParam}/edit`;
      };
    },

    userSettingsPath() {
      return `${this.workspaceRoute}/user_settings`;
    },

    usersPath() {
      return `${this.workspaceRoute}/users`;
    },

    userPath() {
      return (id) => {
        const idParam = id || useUserStore.shownUser?.id;
        return `${this.usersPath}/${idParam}`;
      };
    },

    usersCreatePath() {
      return `${this.usersPath}/create`;
    },

    usersBulkUploadPath() {
      return `${this.usersPath}/upload`;
    },

    websitesPath() {
      return `${this.orgPath()}/websites`;
    },

    websitePath() {
      return (id) => {
        const idParam = id || this.currentWebsite?.id;
        return `${this.websitesPath}/${idParam}`;
      };
    },

    websiteEditPath() {
      return (id) => {
        const idParam = id || this.currentWebsite?.id;
        return `${this.websitesPath}/${idParam}/edit`;
      };
    },

    websitesCreatePath() {
      return `${this.websitesPath}/create`;
    },

    websiteSourceMediumReportPath() {
      return `${this.websitePath()}/reports/source_medium`;
    },

    leadsPath() {
      return `${this.websitePath()}/leads`;
    },

    leadUploadPath() {
      return `${this.leadsPath}/upload`;
    },

    leadPath() {
      return (id) => {
        const idParam = id || this.currentLead?.id;
        return `${this.leadsPath}/${idParam}`;
      };
    },

    mediaSchedulesPath() {
      return `${this.websitePath()}/media_schedules`;
    },

    mediaSchedulePath() {
      return (id) => {
        const idParam = id || this.currentMediaSchedule?.id;
        return `${this.mediaSchedulesPath}/${idParam}`;
      };
    },

    mediaScheduleEditPath() {
      return (id) => {
        const idParam = id || this.currentMediaSchedule?.id;
        return `${this.mediaSchedulesPath}/${idParam}/edit`;
      };
    },

    mediaScheduleAnalysisPath() {
      return (id) => {
        const idParam = id || this.currentMediaSchedule?.id;
        return `${this.mediaSchedulesPath}/${idParam}/analysis`;
      };
    },

    mediaSchedulesCreatePath() {
      return `${this.mediaSchedulesPath}/create`;
    },

    purchasesPath() {
      return `${this.websitePath()}/purchases`;
    },

    purchaseUploadPath() {
      return `${this.purchasesPath}/upload`;
    },

    orgLocationsPath() {
      return `${this.orgPath()}/locations`;
    },

    orgLocationPath() {
      return (id) => {
        const idParam = id || this.currentOrgLocation?.id;
        return `${this.orgLocationsPath}/${idParam}`;
      };
    },

    orgLocationEditPath() {
      return (id) => {
        const idParam = id || this.currentOrgLocation?.id;
        return `${this.orgLocationsPath}/${idParam}/edit`;
      };
    },

    orgLocationsCreatePath() {
      return `${this.orgLocationsPath}/create`;
    },

    trackingTagsPath() {
      return `${this.websitePath()}/tracking_tags`;
    },

    trackingTagPath() {
      return (id) => {
        const idParam = id || this.currentTrackingTag?.id;
        return `${this.trackingTagsPath}/${idParam}`;
      };
    },

    trackingTagEditPath() {
      return (id) => {
        const idParam = id || this.currentTrackingTag?.id;
        return `${this.trackingTagsPath}/${idParam}/edit`;
      };
    },

    trackingTagsCreatePath() {
      return `${this.trackingTagsPath}/create`;
    },

    contactsPath() {
      return `${this.orgPath()}/contacts`;
    },

    contactPath() {
      return (id) => {
        const idParam = id || this.currentContact?.id;
        return `${this.contactsPath}/${idParam}`;
      };
    },

    contactEditPath() {
      return (id) => {
        const idParam = id || this.currentContact?.id;
        return `${this.contactsPath}/${idParam}/edit`;
      };
    },

    contactsCreatePath() {
      return `${this.contactsPath}/create`;
    },

    contactsUploadPath() {
      return `${this.contactsPath}/upload`;
    },
  },
});

import { defineStore } from "pinia";

export const useAdminApiPathStore = defineStore({
  id: "admin_api_paths",

  getters: {
    workspacesPath() {
      return `/workspaces`;
    },
  },
});
